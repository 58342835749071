import React, { useState } from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";
import { Link } from 'gatsby';

import curtainVIDEO from '../assets/images/banner-context.jpg';

import MediaVideoPlayer from '../components/MediaVideoPlayer';

export default function Context() {

  const [playingVideo, setPlayingVideo] = useState(false);

  return (
    <Layout>
      <Seo title="Contexto" />
      <div className="context-wrapper">
        <div className="banner-page video">
          <MediaVideoPlayer 
            url={'https://educacionoh.udd.cl/videos/contexto/video-contexto.mp4'}
            curtain={curtainVIDEO}
            muted={true}
            setPlayingVideo={setPlayingVideo}
            playingVideo={playingVideo}
            />
        </div>

        <section className="py-5 bg-white">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2>Contexto</h2>
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-12 col-md-6">
                <p style={{ "textAlign": "justify" }}>El Covid-19 perturbó cada ámbito de la vida cotidiana. Para detener la ola de contagios se adoptaron medidas tan drásticas como el cierre de colegios, pese a las dramáticas secuelas educacionales y emocionales que ello implicaba. Las salas de clases se trasladaron a los hogares. Las brechas sociales y tecnológicas quedaron al descubierto. Apoderados adquirieron un rol fundamental en la educación y los profesores debieron reinventarse para reemplazar las prácticas tradicionales.</p>
              </div>
              <div className="col-12 col-md-6">
                <p style={{ "textAlign": "justify" }}>En un Chile ideologizado y dividido ante la ruta a seguir, surgieron instancias de solidaridad y creatividad, como también liderazgos que confirmaron su compromiso por el aprendizaje. La siguiente es su historia.</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2>Oral History</h2>
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-12 col-md-6">
                <p style={{ "textAlign": "justify" }}>Toda historia es un tejido -denso y tupido- de hechos y circunstancias. Cada testigo es un universo inestimable de recuerdos. Oral History es una metodología que busca rescatar y recopilar el tejido de cada historia a través de los recuerdos, experiencias y puntos de vista de sus testigos. Se trata de una metodología que reconoce que todos los actores son parte de la historia, donde los recuerdos son una forma de comprender cómo los individuos dan sentido a sus realidades y cómo estas realidades están conectadas con estructuras más amplias. </p>
                <p style={{ "textAlign": "justify" }}>Esta investigación es una recopilación de entrevistas en audio y video -y las respectivas transcripciones- a personajes que fueron testigos y protagonistas de lo acontecido en educación en Chile entre los años 2019 y 2022. El resultado es un registro completo y duradero de las decisiones, acciones e impactos de un momento histórico.</p>
              </div>
              <div className="col-12 col-md-6">
                <p style={{ "textAlign": "justify" }}>El cierre de colegios, sus consecuencias, el miedo imperante en la sociedad, el proceso de continuidad de educación online, el impacto de cada una de las decisiones adoptada por las autoridades, la apertura y el proceso de vacunación son algunas de las aristas abordadas por los testimonios.</p>
                <p style={{ "textAlign": "justify" }}>Oral History Center UDD posee los derechos exclusivos del contenido de la investigación Educando en Pandemia. Oral History Center UDD valora el uso certero de esta investigación para fines académicos, educacionales y no comerciales. En caso de utilizar el material para alguno de estos fines, éste debe ser adecuadamente citado. Por ejemplo: <br />“Texto publicado de los archivos de Educando en Pandemia, Oral History Center UDD,  (nombre, cargo, fecha de la entrevista)”.</p>
              </div>
            </div>

            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-6">
                <Link className="btn btn-primary fw-bold w-50" style={{ 'fontSize': '1em', 'padding': '.8em 0' }} to="/intervieweds">Ir a entrevistas</Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
